@use "./styles_NEW_STRUCTURE/globalAll" as *;

html {
  font-size: 62.5%;
  // @include default-scrollbar;
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

// THIS OVERRIDE MAKES SURE THAT NO p, pre TAGS OVERFLOW THEIR PARENT CONTAINER
p,
pre {
  word-break: break-word;
  white-space: pre-wrap;
}
