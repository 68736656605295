@use "../variables/colors" as *;
@use "../variables/fonts" as *;
@use "../variables/sizes" as *;
@use "./breakpoints" as *;

@mixin default-scrollbar {
  ::-webkit-scrollbar {
    width: 3rem;
    padding: 1rem;
  }
  ::-webkit-scrollbar-track {
    border-radius: 2rem;
    background-color: transparent;
    box-shadow: inset -1rem 0 0 1rem $lightest-blue;
    border: solid 1rem transparent;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 2rem;
    box-shadow: inset -1rem 0 0 1rem $primary-blue;
    border: solid 1rem transparent;
    // border-right: solid 1rem transparent;
    // border-left: solid 1rem transparent;
    min-height: 3rem;
    cursor: grab;
  }
}

@mixin display-flex-column {
  display: flex;
  flex-direction: column;
  gap: $spacing-l;
}
@mixin display-flex-row {
  display: flex;
  flex-direction: row;
  gap: $spacing-l;
}
