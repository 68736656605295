@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

#modal {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  background-color: rgba($primary-blue, 0.4);
  overflow: hidden;

  .alert-box {
    width: 340px;
    height: auto;
    padding: $spacing-l;
    background-color: $white;
    border-radius: $spacing-l;
    //border: 1px solid $primary-navy;
    display: flex;
    flex-direction: column;

    .alert-title {
      width: 100%;
      font-size: 1.5rem;
      font-family: "DM Sans";
      //letter-spacing: -0.15px;
      font-weight: 400;
      color: rgb(38, 38, 38);
      margin-bottom: 20px;
    }

    .alert-message {
      width: 100%;
      font-family: "DM Sans";
      font-size: 1.8rem;
      letter-spacing: -0.15px;
      font-weight: 400;
      margin: $spacing-xl 0;
    }

    .basic-label {
      @include basic-label;
      font-size: 13px !important;
      padding: 10px !important;
    }

    .basic-input {
      @include basic-input;
    }

    .input-state {
      padding: 10px;
      text-align: left;

      span {
        color: $red;
        font-size: 1.2rem;
      }
    }

    .cta-btns {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: $spacing-l;
      justify-content: space-between;
      gap: $spacing-m;

      @include breakpoint-desktop {
        align-items: flex-start;
      }

      .alert-no-btn {
        @include button_secondary;
      }
    }

    .alert-yes-btn {
      @include button_primary;
    }

    .verify-message {
      font-size: 1.8rem;
      margin-bottom: $spacing-l;
      color: black;
    }

    .err {
      color: red;
      font-size: 1.8rem;
      margin-bottom: $spacing-l;
    }
  }
}
