@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

#popup-alert {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  overflow: hidden;

  .alert-box {
    text-align: center; /* centering the text */
    background-image: url("/assets/popupbox_empty.png");
    background-position: left top;
    background-size: cover;
    background-repeat: no-repeat;
    width: 30rem;
    height: 23rem;
    padding: $spacing-xl;
    background-color: $white;
    border-radius: 25px;
    border: none; /* removing the black frame */
    display: flex;
    flex-direction: column;
    justify-content: end;

    @include breakpoint-desktop {
      width: 50rem;
      height: 33rem;
    }

    & > .close-btn {
      background-color: transparent;
      border: none;
      font-size: 3.2rem;
      align-self: flex-end;

      &__icon {
        font-size: 3.2rem;
      }
      &:hover {
        color: $primary-blue;
        cursor: pointer;
      }
    }

    .alert-title {
      margin-top: 0.1em; /* reduced space from the header */
      width: 100%;
      font-size: 2rem;
      font-family: "DM Sans";
      letter-spacing: -0.15px;
      font-weight: 600 !important;
      text-align: center;
      margin-bottom: 0.5em; /* reduced this for a closer gap to .alert-message */

      @include breakpoint-desktop {
        font-size: 3.6rem;
      }
    }

    .alert-message {
      margin-top: 0.5em; /* added this to ensure there's a consistent gap */
      width: 100%;
      font-family: "DM Sans";
      font-size: 1.4rem;
      letter-spacing: -0.15px;
      font-weight: 400;

      @include breakpoint-desktop {
        font-size: 1.8rem;
        margin-bottom: $spacing-xl; /* if you want to keep the bottom margin */
      }
    }

    .cta-btns {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      @include breakpoint-desktop {
        align-items: center;
        flex-direction: row;
      }

      button {
        padding: $spacing-m;
        font-size: 1.8rem;
        font-family: $header-font;
        font-weight: 700;
        width: 70%;
        margin: $spacing-l 10px; /* increased distance between buttons */
        border-radius: 25px;
      }

      .alert-no-btn {
        background-color: $light-grey;
        color: $primary-navy;
        border: none;
        border-radius: 1.6rem;
        align-self: center;
        padding: $spacing-m $spacing-xl;
        &:hover {
          color: $primary-navy;
          box-shadow: 0px 0px 0px 1px $primary-navy;
          background-color: $white;
          cursor: pointer;
        }
      }

      .alert-yes-btn {
        background-color: $primary-blue;
        color: $white;
        outline: none;
        border: 0;
        box-shadow: none;
        margin: 0 !important;

        &:hover {
          cursor: pointer;
          border: 1px solid black;
        }
      }
    }
  }
}
