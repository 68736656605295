@use "./breakpoints" as *;
@use "../variables/colors" as *;
@use "../variables/sizes" as *;
@use "../variables/fonts" as *;

// TEMPORARY WILL BE REWORKED OR REMOVED IN THE FUTURE
@mixin element_icon-space-left {
  font-size: $btn-txt-size;
  margin-left: $spacing-m;
}
@mixin element_icon-space-right {
  font-size: $btn-txt-size;
  margin-right: $spacing-m;
}
@mixin date-input-picker {
  border: none;
  padding: $spacing-s $spacing-l;
  border-radius: 0.8rem;
  font-size: $body-copy-size;
  color: $grey;
  font-weight: 400;
  line-height: 1.8rem;
  width: 100%;
  letter-spacing: 0.2px;

  &:active,
  &:focus {
    border: none;
    outline: none;
  }

  &::-webkit-calendar-picker-indicator {
    background-position: right;
    background-size: auto;
    cursor: pointer;
    position: absolute;
    bottom: $spacing-m;
    left: 0;
    right: $spacing-m;
    top: $spacing-m;
    width: auto;
    transform: translate(-50% -50%);
  }
}
@mixin container_profile-cv-section {
  // padding-bottom: $spacing-l;
  border-bottom: solid 1px $primary-navy;
  margin-bottom: $spacing-l;
}
@mixin container_profile-section-heading {
  display: flex;
  align-items: center;
  margin-bottom: $spacing-l;
}
@mixin text_feature-text {
  font-size: $feature-txt-size-mobile;
  font-family: $body-font;
  font-weight: 500;

  @include breakpoint-tablet-large {
    font-size: $feature-txt-size;
  }
}
@mixin container_card-container {
  display: flex;
  flex-direction: column;
  border: 1px solid $primary-navy;
  border-radius: $border-radius-large;
  padding: $spacing-l;
  box-shadow: $new-box-shadow;
  background-color: $white;
}
@mixin text_card-heading {
  font-size: $feature-txt-size;
  font-weight: 500;
  margin-bottom: $spacing-s;
}
@mixin container_parent {
  //margin-top: $navbar-height;
  background: linear-gradient(
    354.48deg,
    #e6e6e9 10.09%,
    #f1f2f3 60.81%,
    #ffffff 91.67%
  );
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  @include breakpoint-tablet-small {
    background: linear-gradient(
      25.4deg,
      #e6e6e9 59.21%,
      rgba(241, 242, 243, 0) 91.03%
    );
    margin-top: 0;
    display: flex;
    flex-direction: row;
  }
}

@mixin element_link-btn {
  font-size: $btn-txt-size;
  font-family: $header-font;
  // margin-bottom: $spacing-xl;
  border: none;
  background-color: transparent;
  font-weight: $bold-weight;
  cursor: pointer;
  align-items: center;
  flex-direction: row;
  display: flex;

  &:hover {
    color: $primary-blue;
  }
}
@mixin element_right-link-btn {
  @include element_link-btn;
  align-self: flex-end;

  @include breakpoint-tablet-large {
    align-self: flex-end;
  }
}
@mixin element_edit-btn {
  background: none;
  border: none;
  outline: none;

  .icon {
    font-size: $small-label-size;
    margin-left: $spacing-l;
  }

  &:hover {
    cursor: pointer;
    color: $primary-blue;
  }
}
@mixin element_left-aligned-link {
  @include element_link-btn;
  align-self: flex-start;
}
@mixin container_blue-div {
  margin-top: 9vh;
  width: 25%;
  // background: linear-gradient(207.19deg, $primary-blue 22.17%, #062d89 72.79%);
  display: flex;

  @include breakpoint-tablet-large {
    padding-right: $spacing-xl;
    padding-top: $spacing-xl;
    width: 40%;
    justify-content: flex-end;
  }

  @include breakpoint-desktop {
    width: 50%;
    padding-top: $spacing-xl;
    padding-right: $spacing-xl;
  }
}
@mixin text_page-heading {
  font-size: $heading-size;
  font-family: $header-font;
  // line-height: $mobile-heading-line-height;
  margin-bottom: $spacing-xl;
  color: $primary-navy;

  @include breakpoint-tablet-large {
    font-size: $tablet-heading-size;
    // line-height: 6rem;
    max-width: 400px;
    text-align: right;
    // position: fixed;
  }

  @include breakpoint-desktop {
    font-size: $desktop-heading-size;
    line-height: 9rem;
    max-width: 450px;
  }
}
@mixin container_content {
  // border: 2px dashed red;
  padding-bottom: $spacing-xl;
  margin: $spacing-l $spacing-l 0 $spacing-l;
  width: 341px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @include breakpoint-tablet-small {
    padding-top: 9vh;
    margin-top: $spacing-xl;
    margin-left: $spacing-xl;
  }

  // @include breakpoint-desktop {
  //   width: 341px; // Changed from 35%
  //   margin-left: $spacing-xxl;
  //   margin-top: $spacing-xxl;
  //   margin-right: 0;
  // }
}
@mixin mixin-parent-container {
  //margin-top: $navbar-height;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  min-height: 90vh;

  @include breakpoint-tablet-small {
    margin-top: 0;
    flex-direction: row;
    display: flex;
  }
}
@mixin page-heading {
  font-size: $heading-size;
  font-family: $header-font;
  line-height: 5rem;
  margin-bottom: $spacing-l;

  @include breakpoint-tablet-large {
    margin-top: $spacing-xxl;
    margin-right: $spacing-xl;
    color: $white;
    font-size: $tablet-heading-size;
    line-height: $tablet-heading-line-height;
    max-width: 400px;
    text-align: right;
    position: fixed;
  }

  @include breakpoint-desktop {
    font-size: $desktop-heading-size;
    line-height: $desktop-heading-line-height;
    margin-right: $spacing-xxl;
    max-width: 450px;
  }
}
@mixin content-container {
  margin: 0 $spacing-l;
  width: 341px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;

  @include breakpoint-tablet-small {
    padding-top: 9vh;
    margin-left: $spacing-xl;
  }

  @include breakpoint-desktop {
    width: 35%;
    margin-left: $spacing-xxl;
  }

  h1 {
    margin-bottom: $spacing-xl;
  }
}
@mixin container_profile-personal-section-container {
  padding: $spacing-l;
  background-color: #031a4e;
  color: $white;
  border-radius: $border-radius-large;
  box-shadow: 0px 2px 30px rgba(0, 0, 0, 0.25);
  margin-bottom: $spacing-l;

  @include breakpoint-tablet-small {
    width: 736px;
  }

  @include breakpoint-desktop {
    width: 358px;
    margin-left: $spacing-xl;
  }
}
@mixin link-btn {
  font-size: 1.8rem;
  margin: $spacing-l 0 $spacing-xl 0;
  border: none;
  background-color: transparent;
  font-weight: bold;
  cursor: pointer;
  align-items: center;
  align-self: flex-end;
  flex-direction: row;
  display: flex;

  @include breakpoint-tablet-small {
    margin-bottom: $spacing-l 0 $spacing-xxl 0;
  }

  @include breakpoint-tablet-large {
    margin-bottom: $spacing-xl;
    align-items: flex-end;
    align-self: flex-end;
  }
}
@mixin text_profile-name-heading {
  font-family: $header-font;
  font-size: $mobile-name-heading;
  font-weight: 700;
  @include breakpoint-desktop {
    line-height: 4.6rem;
    font-size: $desktop-name-heading;
  }
}
@mixin text_form-label {
  display: inline-block;
  font-weight: $medium-weight;
  font-family: $body-font;
  font-size: $small-label-size;
  margin-bottom: $spacing-xs;

  @include breakpoint-tablet-large {
    font-size: $big-label-size;
  }
}
@mixin form-label-mixin {
  margin-bottom: $spacing-m;
  margin-top: $spacing-l;
  font-family: $body-font;
  font-size: $small-label-size;

  @include breakpoint-tablet-large {
    margin-bottom: $spacing-l;
    font-size: $big-label-size;
    font-weight: $big-label-weight;
  }
}
@mixin basic-textarea {
  font-family: $body-font;
  padding: $spacing-s $spacing-l;
  border: 1px solid rgba($grey, 0.4);
  border-radius: $border-radius-small;
  font-size: $body-copy-size;
  color: $grey;
  font-weight: 400;
  line-height: 1.8rem;
  width: 100%;
  letter-spacing: 0.2px;

  &:focus {
    outline: none;
    border: 2px solid $primary-navy;
    color: $primary-navy;
  }

  @include breakpoint-desktop {
    padding: $spacing-l;
  }
}
