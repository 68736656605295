@use "../globalVariables" as *;
@use "./breakpoints" as *;

@mixin basic-label {
  font-weight: $medium-weight;
  font-family: $body-font;
  font-size: $small-label-size;
  margin-bottom: $spacing-xs;

  @include breakpoint-tablet-large {
    font-size: $big-label-size;
  }
}

@mixin black-bold-label {
  color: $primary-navy;
  font-family: $header-font;
  font-weight: $bold-weight;
  font-size: $btn-txt-size;
}
