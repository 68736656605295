@use "../variables/colors" as *;
@use "./breakpoints" as *;

@mixin blue-div {
  display: none;
  background: $blue-gradient;
  flex-direction: column;
  align-items: flex-end;

  @include breakpoint-tablet-small {
    display: flex;
    flex-basis: 25%;
  }
  @include breakpoint-tablet-large {
    flex-basis: 50%;
  }
}
