@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

.dropdown {
  @include default-dropdown;
  // @include validation-alert;
  & > .input-label {
    @include basic-label;
    margin-bottom: $spacing-m;
  }
  & > .tips {
    font-size: $tips-text-size;
    margin-top: $spacing-s;
  }
}
