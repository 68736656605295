@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

.activate-container {
  //margin-top: $navbar-height;
  width: 100%;
  min-height: calc(100vh - $navbar-height);
  display: flex;
  flex-direction: column;
  color: $primary-navy;
  padding: 0 $spacing-l $spacing-l $spacing-l;
  align-items: center;

  background-image: url("../../public/img/backgrounds/background_tablet_registration_03.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  @include breakpoint-tablet-small {
    align-items: flex-start;
    padding: $spacing-l 0;
    background-image: url("../../public/img/backgrounds/background_desktop_registration_03.png");
    display: flex;
    flex-direction: row;
  }

  .blue-div {
    @include container_blue-div;
    margin-top: $spacing-xl;

    @include breakpoint-tablet-small {
      padding-top: 0;
    }

    & > h1 {
      @include text_page-heading;
    }
  }

  .content-container {
    background-color: rgba($background-grey, 0.3);
    padding: 0 $spacing-l $spacing-l $spacing-l;
    border-radius: $spacing-l;
    width: 341px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @include breakpoint-tablet-small {
      padding-top: $spacing-xl;
    }
    
    & > h1 {
      @include text_page-heading;
    }

    & > h2 {
      @include text_feature-text;
    }

    & > .redirect-text {
      font-size: 1.6rem;

      & > span {
        font-weight: 700;
      }
    }
  }
}
