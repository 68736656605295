@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

.no-access-container {
  padding: $spacing-l;
  color: $primary-navy;
  //margin-top: $navbar-height;
  display: flex;
  min-height: calc(100vh - $navbar-height);
  flex-direction: column;
  // justify-content: center;
  align-items: center;

  background-image: url("../../../../../public/img/backgrounds/background_tablet_registration_06.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  @include breakpoint-tablet-small {
    background-image: url("../../../../../public/img/backgrounds/background_desktop_registration_06.png");
  }

  .container {
    width: 340px;
    padding: $spacing-l;
    border-radius: $spacing-l;
    background-color: rgba($background-grey, 0.3);

    .no-access-header {
      font-size: 4.8rem;
      line-height: 5.5rem;
      margin-bottom: $spacing-l;
      font-family: $header-font;

      @include breakpoint-tablet-small {
        margin-bottom: $spacing-l;
      }
    }

    .no-access-p {
      font-size: 2.5rem;
    }

    .btn-div {
      margin-top: $spacing-xl;
      // width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .no-access-btn {
        @include button-primary;
        // padding: $spacing-m;
        // border-radius: 5px;
        // border: 1px solid $primary-blue;
        // width: 40%;
        // text-align: center;
        // text-decoration: none;
        // color: $primary-blue;
        // font-weight: 600;
        // margin: $spacing-l $spacing-l 0 0;
        // background-color: $white;

        // @include breakpoint-tablet-large {
        //   font-size: 22px;
        // }
      }

      .no-access-btn:first-child {
        margin-bottom: $spacing-l !important;
      }
    }

    .no-access-btn:hover {
      background-color: $primary-blue;
      color: white;
    }
  }
}
