@use "../globalVariables" as *;
@use "./breakpoints" as *;

@mixin button_primary {
  font-family: $header-font, sans-serif;
  padding: $spacing-m $spacing-xl;
  outline: none;
  border: 0;
  color: $white;
  // margin-bottom: $spacing-l;
  font-size: $btn-txt-size;
  font-weight: $link-weight;
  border-radius: 1.6rem;
  background-color: $primary-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  height: 4.3rem;
  cursor: pointer;
  text-decoration: none;

  $border-width: 3px; // WE BREAK OUT THE BORDER WIDTH INTO A VARIABLE FOR EASY FUTURE MODIFICATIONS.
  width: calc(
    100% - calc($border-width * 2)
  ); // WE NEED TO SUBTRACT THE BOXSHADOW BORDER FROM THE WIDTH AS BOX SHADOW IS NOT PART OF THE SIZE CALCULATION.
  box-shadow: 0px 0px 0px $border-width $primary-blue; // WE USE THIS A BORDER TO PREVENT SIZING CHANGES DUE TO BORDER ANIMATION.
  margin: $border-width !important; // TO OFFSET THE BOXSHADOW BORDER. WE APPLY IMPORTANT AS IT IS EASILY OVERRIDDEN.

  transition: color 0.1s ease, background-color 0.1s ease, box-shadow 0.2s ease;

  &:hover {
    color: $primary-navy;
    background-color: $white;
  }

  &:disabled {
    background-color: $light-grey;
    color: $grey;
    box-shadow: 0px 0px 0px 1px $grey;
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

@mixin button_secondary {
  padding: $spacing-m $spacing-xl;
  background-color: $light-grey;
  outline: none;
  border: none;
  justify-content: center;
  border-radius: 1.6rem;
  font-weight: 700;
  height: 4.3rem;
  line-height: 22.97px;
  font-family: $header-font;
  font-style: normal;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: $primary-navy;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  $border-width: 2px; // WE BREAK OUT THE BORDER WIDTH INTO A VARIABLE FOR EASY FUTURE MODIFICATIONS.
  width: calc(
    100% - calc($border-width * 2)
  ); // WE NEED TO SUBTRACT THE BOXSHADOW BORDER FROM THE WIDTH AS BOX SHADOW IS NOT PART OF THE SIZE CALCULATION.
  box-shadow: 0px 0px 0px $border-width $light-grey; // WE USE THIS A BORDER TO PREVENT SIZING CHANGES DUE TO BORDER ANIMATION.
  margin: $border-width !important; // TO OFFSET THE BOXSHADOW BORDER. WE APPLY IMPORTANT AS IT IS EASILY OVERRIDDEN.

  transition: box-shadow 0.1s ease, color 0.1s ease, background-color 0.1s ease;

  &:hover {
    box-shadow: 0px 0px 0px $border-width $primary-navy;
    color: $primary-navy;
    background-color: $white;
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

@mixin delete-button {
  padding: $spacing-m $spacing-xl;
  background-color: transparent;
  outline: none;
  border: none;
  justify-content: center;
  font-weight: 700;
  height: 4.3rem;
  line-height: 22.97px;
  font-family: $header-font;
  font-style: normal;
  font-size: 18px;
  display: flex;
  align-items: center;
  gap: 1rem;
  color: $primary-navy;
  align-items: center;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: $dark-red;
  }
}

@mixin button_alert {
  padding: $spacing-s $spacing-l;
  border: 0;
  outline: none;
  border-radius: 1.6rem;
  font-weight: 700;
  height: 4.3rem;
  line-height: 22.97px;
  font-family: $header-font;
  font-style: normal;
  font-size: 18px;
  display: flex;
  background-color: $red;
  color: $white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  width: 100%;

  $border-width: 2px; // WE BREAK OUT THE BORDER WIDTH INTO A VARIABLE FOR EASY FUTURE MODIFICATIONS.
  width: calc(
    100% - calc($border-width * 2)
  ); // WE NEED TO SUBTRACT THE BOXSHADOW BORDER FROM THE WIDTH AS BOX SHADOW IS NOT PART OF THE SIZE CALCULATION.

  box-shadow: 0px 0px 0px $border-width $red; // WE USE THIS A BORDER TO PREVENT SIZING CHANGES DUE TO BORDER ANIMATION.
  margin: $border-width !important; // TO OFFSET THE BOXSHADOW BORDER. WE APPLY IMPORTANT AS IT IS EASILY OVERRIDDEN.

  transition: color 0.1s ease, background-color 0.1s ease, box-shadow 0.1s ease;

  &:hover {
    box-shadow: 0px 0px 0px $border-width $dark-red;
    color: $white;
    background-color: $dark-red;
  }

  a {
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

@mixin button_clickable-tag {
  border: 1px solid $primary-navy;
  border-radius: 0.8rem;
  font-family: $body-font;
  padding: $spacing-s $spacing-m;
  font-size: 1.2rem;
  background: none;
  margin: 0 $spacing-s $spacing-s 0;
  background-color: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  @include breakpoint-tablet-large {
    margin: 0 $spacing-m $spacing-m 0;
    font-size: $body-copy-size;
  }
}

@mixin button_borderless {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: $primary-navy;
  font-family: $header-font;
  font-weight: $bold-weight;
  font-size: $btn-txt-size;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  text-decoration: none;

  transition: color 0.1s ease, translate 0.1s ease, scale 0.1s ease;

  &:hover {
    color: $primary-blue;
    translate: 0.5rem 0;
  }

  &:active {
    scale: 0.95;
  }

  a {
    display: flex;
    align-items: center;
    color: $primary-navy;
    text-decoration: none;
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

@mixin button_icon_scale {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: transparent;
  border: 0;
  transform: scale(1);
  transition: transform 0.1s ease, color 0.1s ease;

  &:hover {
    transform: scale(1.2);
  }
}

@mixin button_icon_scale_white {
  color: $white;
  filter: drop-shadow(0px 0px 1px $primary-navy);
  @include button_icon_scale;
}

@mixin button-link {
  display: flex;
  align-items: center;
  gap: 1rem;
  text-decoration: none;
  font-family: $header-font;
  font-weight: $bold-weight;
  font-size: $btn-txt-size;
  color: $primary-navy;
  transition: color 0.1s ease;

  &:hover {
    color: blue;
  }
}

@mixin close-popup-btn {
  align-items: center;
  align-self: flex-end;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  display: flex;
  font-size: 3rem;
  justify-content: center;
}
