@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

#subscription-popup-alert {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  height: 100vh;
  width: 100vw;
  background-color: rgba($primary-blue, 0.4);
  overflow: hidden;

  .alert-box {
    background-image: url("/assets/popupbox_empty.png");
    background-position: left top;
    background-size: cover;
    background-repeat: no-repeat;
    width: 50.3rem;
    // height: 57rem;
    padding: $spacing-xxl $spacing-xl $spacing-xl $spacing-xl;
    border-radius: $spacing-xl;
    // border: 1px solid $primary-navy;
    display: flex;
    align-items: center;
    flex-direction: column;

    & > .close-btn {
      background-color: transparent;
      border: none;
      font-size: 3.2rem;
      align-self: flex-end;

      &__icon {
        font-size: 3.2rem;
      }
      &:hover {
        color: $primary-blue;
        cursor: pointer;
      }
    }

    .alert-title {
      width: 100%;
      font-size: 3.6rem;
      font-family: "DM Sans";
      letter-spacing: -0.15px;
      font-weight: 600 !important;
      text-align: center;
      margin-bottom: $spacing-l;
    }

    .alert-message {
      width: 100%;
      font-family: "DM Sans";
      font-size: 1.8rem;
      // letter-spacing: -0.15px;
      font-weight: 400;
      margin-bottom: $spacing-l;
      // margin: $spacing-l 0 !important;
    }

    .first {
      font-weight: 500;
      font-size: 1, 8rem;
      margin-bottom: $spacing-l;
    }

    .payment-link {
      @include button-primary;
      margin-bottom: $spacing-l !important;
      margin-top: $spacing-l !important;
    }

    .meeting-link {
      @include button-secondary;
    }

    // .cta-btns {
    //   width: 100%;
    //   display: flex;
    //   flex-direction: column;
    //   align-items: center;

    //   @include breakpoint-desktop {
    //     align-items: flex-start;
    //   }

    //   button {
    //     padding: $spacing-m;
    //     font-size: 1.8rem;
    //     font-family: $header-font;
    //     font-weight: 700;
    //     width: 70%;
    //     margin: $spacing-m 0;
    //     border-radius: 25px;
    //   }

    //   .alert-no-btn {
    //     background-color: $light-grey;
    //     color: $primary-navy;
    //     border: none;
    //     border-radius: 1.6rem;
    //     align-self: center;
    //     padding: $spacing-m $spacing-xl;

    //     &:hover {
    //       color: blue;
    //       box-shadow: 0px 0px 0px 1px $primary-navy;
    //       background-color: $white;
    //       cursor: pointer;
    //     }
    //   }

    //   .alert-yes-btn {
    //     background-color: $red;
    //     color: $white;
    //     border: none;
    //     border-radius: 1.6rem;
    //     align-self: center;
    //     padding: $spacing-m $spacing-xl;
    //     &:hover {
    //       background-color: $dark-red;
    //       cursor: pointer;
    //     }
    //   }
    // }
  }
}
