@use "/src/styles_NEW_STRUCTURE/globalAll" as *;
@use "/src/styles_NEW_STRUCTURE/variables/fonts" as *;
@use "/src/styles_NEW_STRUCTURE/variables/colors" as *;
@use "/src/styles_NEW_STRUCTURE/variables/sizes" as *;
@use "/src/styles_NEW_STRUCTURE/mixins/breakpoints" as *;
.school-user-search-students-wrapper {
  //  margin-top: $navbar-height;
  padding: $spacing-l $spacing-l $spacing-xxl $spacing-l;
  min-height: calc(100vh - $navbar-height);
  display: flex;
  background-image: url("../../../../public/img/backgrounds/background_tablet_registration_01.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  @include breakpoint-tablet-small {
    background-image: url("../../../../public/img/backgrounds/background_desktop_registration_01.png");
    align-items: center;
    justify-content: center;
  }

  .school-user-search-students {
    // background: linear-gradient(291.19deg, #f0f0f0 0.82%, #ffffff 97.49%);
    .margin {
      margin-top: 2rem;
      cursor: pointer;
    }
    .font {
      font-size: 1.8rem;
    }
    display: flex;
    flex-direction: column;
    gap: 10px;
    min-height: 100vh;

    @include breakpoint-tablet-large {
      flex-direction: row; // rewrite this to next br point
    }
    .filter-students {
      flex: 4;
      display: flex;
      flex-direction: column;
      padding: 1rem;

      // gap: 3rem;

      // padding-top: 12rem;
      // for now

      // &-form {
      //   .dropdown-search-schools {
      //     // border-radius: 0.8rem;
      //     // border: 1px solid #041130;

      //     #dropdown-search-schools {
      //       font-size: 1.8rem;
      //     }
      //   }

      //   .btn-container {
      //     display: flex;
      //     flex-direction: column;
      //     gap: 1rem;
      //   }
      // }

      h1 {
        font-size: 3rem;
      }

      .filter-students-form {
        width: 36rem;
        margin-top: 3.6rem;
        display: flex;
        flex-direction: column;
        gap: $spacing-xl;

        p {
          font-size: 1.2rem;
          margin-top: $spacing-s;
        }

        .free-search {
          display: flex;
          flex-direction: column;

          & .title-label {
            font-size: 2rem;
            font-weight: 500;
            margin-bottom: $spacing-m;
          }

          & input {
            @include basic-input;
            font-size: 1.6rem;
            // padding: 1rem;
          }
        }
        .school-dropdown {
          display: flex;
          flex-direction: column;
          & #school-search {
            border-radius: 0.8rem;
            font-size: 1.8rem;
            padding: 0.3rem;
            background-color: transparent;
          }
          & .title-label {
            font-size: 2rem;
            font-weight: 500;
            margin-bottom: $spacing-m;
          }
        }

        .dropdown-search-schools {
          // border-radius: 0.8rem;
          // border: 1px solid #041130;

          #dropdown-search-schools {
            font-size: 1.6rem;
          }
        }

        .search-reset-btn,
        .search-save-btn {
          @include button_primary;
          .right-icon {
            @include element_icon_space-left;
          }
        }
        .search-reset-btn {
          border: 2px solid #d6d6d6;
          background-color: $white;
          font-size: 1.8rem;
          color: #7c7c7c;
          align-self: flex-start;
          margin: $spacing-l 0 $spacing-xl 0;
          box-shadow: none;
        }
      }

      .btn-container {
        display: flex;
        flex-direction: column;
        gap: 1rem;
      }

      .close {
        align-self: flex-end;
        padding: 1rem;
        background-color: transparent;
        font-size: 2.5rem;
        border: none;
      }
    }

    .student-results {
      flex: 6;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      padding: 1rem;

      &-cards {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        padding: 1rem;
        // @include breakpoint-tablet-large {
        //   padding-top: 10rem; // rewrite this to next br point
        // }
        .zero-results {
          padding-top: 3rem;
          font-size: 2.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
