@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

.dropdown-single {
  @include default-dropdown;
  padding: 2px;
  font-size: $body-copy-size;
  border-radius: $border-radius-small;
  background-color: $white;

  .input-section {
    display: flex;
    justify-content: space-between;

    .input {
      border: none !important; // UGLY SOLUTION FOR NOW
      outline: none !important;
      box-shadow: none !important;
      padding: $spacing-m $spacing-l;
      font-size: 1.4rem;
      font-family: $body-font;
      background-color: transparent;
      margin: 0 !important;
    }

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
      color: $grey;
      padding-right: $spacing-l;

      .up,
      .down {
        font-size: 1.6rem;

        &:hover {
          cursor: pointer;
        }
      }

      &--up {
        color: $primary-blue;
      }
    }
  }

  .dataResult {
    list-style: none;
    max-height: 26rem;
    overflow: hidden;
    overflow-y: auto;

    .item {
      padding: $spacing-s $spacing-l;
      white-space: nowrap;
      color: black;

      &:hover {
        background-color: $primary-blue;
        color: $white;
        cursor: pointer;
      }
    }
  }
}

.container-border-color-gray {
  border: 1px solid rgba($grey, 0.4);
}

.container-border-color-black {
  border: 1px solid $primary-navy;
}
