@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

.school-user-form-wrapper {
  .form-background {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: $white;
    box-shadow: 0rem 0.2rem 3rem 0rem rgba(0, 0, 0, 0.25);
    border-radius: $border-radius-large;

    .school-user-profile-form {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: $spacing-l;
      padding: $spacing-l;
      width: 343px;

      .profile-img-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;

        .edit-btn {
          color: #212121;
          border: none;
          background-color: rgba(0, 0, 0, 0);
          cursor: pointer;
          .icon {
            font-size: 1.8rem;
          }
        }
      }

      label {
        @include basic-label;
      }

      input {
        margin: 0rem 0.9rem 0rem 0rem;
        background-color: $white;
        width: 100%;
      }

      .school-dropdown-container {
        display: flex;
        flex-direction: column;
        input {
          color: $grey;
        }

        &.invalid {
          color: $red;
          border-color: $red;
          margin-bottom: 3.2rem;
        }

        .validation-message {
          color: $red;
          position: absolute;
          font-size: 1rem;
        }
      }

      // COMMENTED OUT FOR NOW
      // .regions-dropdown-container {
      //   display: flex;
      //   flex-direction: column;
      //   min-width: 34.1rem;
      //   max-width: 34.1rem;
      //   margin: 0rem 0.9rem 0rem 0.8rem;
      //   margin-bottom: 0rem !important;
      //   height: 100%;
      //   label {
      //     margin-left: 0rem;
      //   }

      //   > div {
      //     border: 0.1rem solid $grey;
      //   }
      // }

      .phone-input-container {
        .phone-not-displayed-text {
          margin-bottom: $spacing-xs;
          font-weight: 500;
          font-size: 1.2rem;
          letter-spacing: -0.15px;
          color: #6d6d6d;
        }
      }

      .submit-btn {
        @include button-primary;
      }
    }
  }
}
