@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

.preview {
  //align-self: center;
  position: relative;
  flex-shrink: 0;
  flex-grow: 0;

  img {
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 0px;
    min-width: 0px;
    object-fit: cover;
    object-position: center;
    background-color: $white;
  }

  .loading-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $white;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    transition: opacity 0.1s ease;
    pointer-events: none;

    &.show {
      opacity: 1;
    }

    &.hide {
      opacity: 0;
      transition-delay: 0.2s;
    }
  }
}
