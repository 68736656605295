@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

.educations-container {
  display: flex;
  gap: 0.8rem;

  &__program-card {
    color: $primary-navy;
    display: flex;
    flex-direction: column;
    width: 343px;
    height: max-content;
    gap: $spacing-m;
    background-color: white;
    padding: $spacing-m;
    border-radius: 0.6rem;
    box-shadow: 0 0.4rem 0.4rem 0 rgba(0, 0, 0, 0.25);

    &--heading {
      display: flex;
      justify-content: space-between;

      & > .edit-btns-container {
        display: flex;
        justify-content: center;
        gap: $spacing-m;

        & > button {
          border: none;
          background: none;
          & > .icon {
          }
        }
      }
    }

    &--code,
    &--year,
    &--url {
      font-size: 1.2rem;
    }

    &--url {
      display: flex;
    }
  }

  &__edit-program-form {
    display: flex;
    flex-direction: column;
    gap: $spacing-m;

    & > input {
      border-radius: 0.8rem;
      border: 1px solid #6d6d6d;
      padding: $spacing-s;
      font-family: $body-font;
      font-size: 1.2rem;
    }
  }
}
