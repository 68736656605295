@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

#school-user-education-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2rem;
  color: $primary-navy;

  .heading-edit-button-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-family: $header-font;

    .header {
      font-size: $feature-txt-size;
      display: flex;
      // flex-grow: 1;
      margin-right: $spacing-m;
    }

    .icon {
      font-size: $feature-txt-size;
      margin-right: $spacing-m;
    }

    .edit-educations-button {
      border: none;
      background-color: rgba(0, 0, 0, 0);
      cursor: pointer;
    }
  }

  .program-cards-container {
    display: grid;
    gap: $spacing-l;
    justify-items: center;

    grid-template-columns: repeat(1, 1fr);

    @include breakpoint-tablet-small {
      justify-items: flex-start;

      grid-template-columns: repeat(2, 1fr);
    }
  }

  .add-new-education-button {
    @include button-link;
    background: none;
    border: none;
    cursor: pointer;

    h3 {
      display: flex;
      justify-content: center;

      @include breakpoint-tablet-large {
        justify-content: flex-start;
      }
    }
  }

  .icon {
    font-size: 1.8rem;
  }

  .education-name-input-container,
  .education-code-input-container,
  .education-year-input-container,
  .education-url-input-container {
    display: flex;
    flex-direction: column;
    label {
      font-size: 1.6rem;
      margin-bottom: $spacing-m;
    }
  }

  #education-name-input,
  #education-code-input,
  #education-year-input,
  #education-url-input {
    box-shadow: inset 0 0.4rem 0.4rem rgba(0, 0, 0, 0.25);
    border: 1px solid #6d6d6d;
    border-radius: 0.8rem;
    min-width: 21.1rem;
    min-height: 4rem;
    padding: $spacing-m;
    font-family: $body-font;
  }

  span {
    color: red;
    font-size: 1.2rem;
    font-weight: 500;
  }

  .add-education-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    cursor: pointer;

    background-color: rgba(255, 255, 255, 0);
    .icon {
      color: #133697;
      height: 2.5rem;
      width: 2.5rem;
    }
    // }
  }

  .view-students-button {
    @include button-primary;
    width: max-content;
    align-self: center;
    // align-self: center;
    // font-size: 2rem;
    // background: linear-gradient(white, white) padding-box,
    //   linear-gradient(to right, #b1c7fc, #0b3187) border-box;
    // border-radius: 2.5rem;
    // border: 0.3rem solid transparent;
    // width: 26.3rem;
    // height: 7rem;
    // padding: 0.5rem;
    // background-color: rgba(255, 255, 255, 0);
    // color: #0e62ad;
  }

  .view-students-button:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}
