@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

.school-user-profile-created {
  display: flex;
  flex-direction: column;
//  margin-top: $navbar-height;
  min-height: calc(100vh - $navbar-height);
  padding: $spacing-l;

  background-image: url("../../../../public/img/backgrounds/background_tablet_registration_01.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  align-items: center;

  @include breakpoint-tablet-small {
    padding: 0;
    background-image: url("../../../../public/img/backgrounds/background_desktop_registration_01.png");
  }

  .content-section {
    padding: $spacing-l;
    border-radius: $spacing-l;
    background-color: rgba($background-grey, 0.3);
    width: 340px;
    display: flex;
    flex-direction: column;
    gap: $spacing-l;

    @include breakpoint-tablet-small {
      margin: $spacing-l 0;
    }

    h1 {
      font-size: 4.8rem;
      line-height: 5rem;
      // margin-bottom: $spacing-l;
    }

    h2 {
      font-size: 2.4rem;
      font-weight: 400;
      margin-bottom: $spacing-l;
    }

    button[type="button"] {
      @include button_primary;
      // max-width: 30rem;

      .right-icon {
        @include element_icon_space-left;
      }
    }
  }
}
