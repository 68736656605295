@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

#pagination-container {
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
  height: 3rem;

  margin-bottom: 2rem;
  gap: $spacing-m;

  p {
    font-size: 1.8rem;
    height: 100%;
    line-height: 2.7rem;
    white-space: nowrap;
    color: $primary-navy;
    font-weight: 400;
  }

  .page-button {
    color: $dark-grey;
    text-align: center;
    font-size: 1.6rem;
    border: none;
    background-color: transparent;
    font-size: 1.8rem;
    margin: 0 $spacing-xl;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: $primary-blue;
      cursor: pointer;
    }

    @include breakpoint-desktop {
      font-size: 1.8rem;

      .icon-next {
        margin: 0 0 0 $spacing-l;
      }

      .icon-back {
        margin: 0 $spacing-l 0 0;
      }
    }
  }

  .numbers-container {
    max-height: 20rem;
    overflow: hidden;
    overflow-y: auto;
    margin: 0 0 $spacing-l 0;
    .select--option-btn {
      font-size: 1.8rem;
      list-style: none;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
