// Heading
$desktop-heading-size: 8.4rem;
$tablet-heading-size: 6.4rem;
$tablet-small-heading-size: 5.4rem;
$mobile-name-heading: 2.4rem;

// PHASE BELOW OUT ###
$heading-size: 5.4rem;
// ###################

// SHOULD BE RENAMED TO A MORE ACCURATE NAME
$desktop-name-heading: 3.2rem;

// Sub-heading
$sub-heading-size: 2rem;

//Button text size
$btn-txt-size: 1.8rem;

// Feature text size
$feature-txt-size: 1.8rem;
$feature-txt-size-mobile: 1.6rem;

// Section heading size
$section-heading-desktop: 2.4rem;
$section-heading-mobile: 1.8rem;

// Body copy
$body-copy-size: 1.2rem;
$chat-font-size: 1.6rem;

// Label sizes
$small-label-size: 1.8rem;
$big-label-size: 2rem;

// Font-weight
$light-weight: 300;
$medium-weight: 500;
$bold-weight: 700;

// Tips text on search
$tips-text-size: 12px;

// Link
// PHASE THESE OUT ###
$link-weight: 700;
$big-label-weight: 500;
// ###################

// Font family
$header-font: "Urbanist", sans-serif;
$body-font: "DM Sans";

// Line height
// PHASE THESE OUT #################
$desktop-heading-line-height: 8.3rem;
$tablet-heading-line-height: 6.2rem;
$mobile-heading-line-height: 5.3rem;
// ##################################
