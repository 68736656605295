$border-color: #249791;

$background-color: #f5f6f8;
// $background-grey: #dfdede;
$link-color: #18a0fb;
$highlight1: #e693be;
$highlight2: #39ba65;
$not-selected: #bdd3e1;
$selected: #3471ad;
$button1: #df5296;
$error-message: #ed4337;

$item-green-color: #00d45a;
$icon-color: #818484;
$selected-border-color: rgb(66, 155, 245);

$box-shadow: 3px 3px 3px rgba(35, 99, 246, 0.4);
$new-box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.35);

//! NEW COLOR VARIABLE
$dark-grey: #4c4c4c;
$grey: #6d6d6d;
$light-grey: #7c7c7c;
$dark-blue: #031a4e;
$primary-blue: #2363f6;
$chat-blue: #7dbce9;
$light-blue: #b1c7fc;
$lightest-blue: #e8f1fe;
$primary-navy: #000000;
$white: #ffffff;
$red: #f25f5c;
$dark-red: #d01411;
$green: #05af4c;
$footer-bg: #020e27;
$light-grey: #f9f9f9;
$yellow: #fece2f;
$light-green: #a9efbc;

// GRADIENTS
$white-gradient: linear-gradient(295.79deg, #f0f0f0 -0.43%, #ffffff 97.98%);
$grey-gradient: linear-gradient(
  25.4deg,
  #e6e6e9 59.21%,
  hsla(210, 8%, 95%, 0) 91.03%
);
$blue-gradient: linear-gradient(
  207.19deg,
  $primary-blue 22.17%,
  #062d89 72.79%
);

// APPROVED COLOR PALETTE
$primary-grey: #f0f0f0;
$primary-navy: #041130;
$primary-pink: #ff0066;

$secondary-blue: #7dbce9;
$secondary-mint: #ceeae6;
$secondary-purple: #9998d8;
$secondary-orange: #fba066;
$secondary-yellow: #ffde52;
$secondary-pink: #efbcd5;

$background-grey: #f7f7f7;
