@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

#reset-password-page {
  @include page-base;
  padding: $spacing-l;
  color: $primary-navy;
  // margin-top: $navbar-height;
  display: flex;
  flex-direction: column;
  // justify-content: center;
  align-items: flex-start;

  background-image: url("../../public/img/backgrounds/background_tablet_registration_04.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  @include breakpoint-tablet-small {
    padding: 0;
    background-image: url("../../public/img/backgrounds/background_desktop_registration_04.png");
    height: 100%;
    min-height: 100vh;
  }
  @include breakpoint-tablet-small {
    justify-content: center;
  }

  .reset-container {
    margin-top: $spacing-l;
    padding: $spacing-l;
    border-radius: $spacing-l;
    background-color: rgba($background-grey, 0.3);
    width: 340px;
    font-size: $body-copy-size;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $spacing-l;

    @include breakpoint-tablet-large {
      max-width: 34rem;
    }

    & > label {
      @include basic-label;
    }

    & > input {
      @include basic-input;
    }

    & > button {
      @include button_primary;
      align-self: center !important;
      margin-top: $spacing-l;
    }
  }
}
