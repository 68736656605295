@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

.input-with-validation-msg {
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 0;
  // margin: 0 0 1.6rem 0;

  &.invalid {
    color: $red;
    border-color: $red;
    // margin-bottom: 2rem;
  }

  @include default-scrollbar;

  .input-wrapper {
    position: relative;
    .input-textarea,
    .input-regular {
      margin: 0;
      padding: 0;
      z-index: 2;
      resize: none;

      // TO HIDE THE BUILD IT "EYE" ICON FOR EDGE
      &::-ms-reveal,
      &::-ms-clear {
        display: none;
      }
      @include basic-input;
    }

    .input-textarea {
      min-height: 10rem;
    }
  }

  .input-label {
    @include basic-label;
    display: flex;
    align-items: center;
    margin-bottom: $spacing-m;

    & > .required-mark {
      color: gray;
      font-size: 70%;
    }
  }

  .input-tips-text {
    font-size: 12px;
    margin-top: $spacing-s;
  }

  .validation-msg {
    transition: top 0.2s ease, opacity 0.2s ease, color 0.2s ease;
    font-size: 0.8rem;

    &.show {
      top: calc(100% + 0.5rem);
      opacity: 1;
      font-size: 12px;
    }

    &.hide {
      top: calc(100% - 1.5rem);
      display: none;
    }
  }

  .description {
    margin-top: 10px;
    margin-bottom: 0.8rem;
  }
}
