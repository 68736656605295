@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

.loader-container {
  width: 100%;
  height: 100vh;
  background-color: rgba($color: $secondary-blue, $alpha: 0.5);
  position: relative;

  .loader {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;

    .text {
      margin-top: 2.5rem;
      font-size: 2rem;
      color: $white;
      text-align: center;
      text-transform: uppercase;
      font-family: $header-font;
      font-weight: $bold-weight;
    }
  }
}
