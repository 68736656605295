@use "../variables/sizes" as *;

// ########## BREAKPOINTS ##########
@mixin breakpoint-mobile-only {
  @media screen and (max-width: $phone-size) {
    @content;
  }
}
@mixin breakpoint-mobile {
  @media screen and (min-width: $phone-size) {
    @content;
  }
}
@mixin breakpoint-tablet-small {
  @media screen and (min-width: $tablet-small-size) {
    @content;
  }
}
@mixin breakpoint-tablet-large {
  @media screen and (min-width: $tablet-big-size) {
    @content;
  }
}
@mixin breakpoint-desktop {
  @media screen and (min-width: $desktop-size) {
    @content;
  }
}
