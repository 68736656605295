@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

// container_parent

.change-password-container {
  @include container_parent;

  .blue-div {
    @include container_blue-div;
    & > h1 {
      @include text_page-heading;
    }
  }

  .change-password-form {
    @include container_content;

    & .mobile-heading {
      align-self: flex-start;
      padding-bottom: 30px;
    }

    font-size: $body-copy-size;
    // margin-top: $navbar-height;

    display: flex;
    flex-direction: column;
    align-items: center;

    & > label {
      @include text_form-label;
      align-self: start;
    }

    & > input {
      @include basic-input;
      margin-bottom: 2rem;
    }

    & > button {
      @include button_primary;
      align-self: center !important;
      margin-top: $spacing-l;

      .icon {
        @include element_icon-space-left;
      }
    }
  }
}
