@use "/src/styles_NEW_STRUCTURE/globalAll" as *;
@import "../../../styles_NEW_STRUCTURE/globalVariables";
@import "../../../styles_NEW_STRUCTURE/mixins/buttons";

.image-card-component {
  display: flex;

  &.edit {
    width: 100%;
    background-color: $white;
    flex-direction: column;
    gap: $spacing-m;
    border-radius: $border-radius-large;
    padding: $spacing-l;
  }

  .close-button {
    @include button_icon_scale;
    height: 4rem;
    width: 4rem;
    align-self: flex-end;
    font-size: 3rem;
    &:hover {
      color: $primary-blue;
    }
  }

  .preview {
    align-self: center;
    position: relative;

    img {
      position: relative;
      width: 100%;
      height: 100%;
      min-height: 0px;
      min-width: 0px;
      object-fit: cover;
      object-position: center;
      border-radius: $border-radius-large;
      background-color: $white;
    }

    .loading-overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;

      background-color: $white;
      border-radius: $border-radius-large;
      display: flex;
      justify-content: center;
      align-items: center;
      pointer-events: none;
      transition: opacity 0.1s ease;
      pointer-events: none;

      &.show {
        opacity: 1;
      }

      &.hide {
        opacity: 0;
        transition-delay: 0.2s;
      }
    }

    .edit-image-button {
      position: absolute;
      bottom: 0;
      right: 0;
      font-size: 2.4rem;
      height: 4rem;
      width: 4rem;
      @include button_icon_scale_white;

      @include breakpoint-desktop {
        font-size: 3rem;
      }
    }
  }

  .error-msg {
    color: $red;
    font-size: $body-copy-size;
    font-family: $body-font;
  }

  .choose-image-button {
    padding: $spacing-l;
    align-self: center;
    label {
      cursor: pointer;
      display: flex;
      align-items: center;
      gap: $spacing-m;

      input[type="file"] {
        display: none;
      }

      span {
        @include button-borderless;
      }
    }
  }

  .delete-button {
    @include button-alert;
  }

  .save-button {
    @include button-primary;
  }
}
