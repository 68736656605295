@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

#internperiod-container {
  border-radius: $border-radius-large;
  display: flex;
  background-color: $white;
  width: 340px;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.15);
  padding: $spacing-m;
  margin-bottom: $spacing-l;
  column-gap: $spacing-m;

  cursor: pointer;

  @include breakpoint-tablet-small {
    padding: $spacing-l $spacing-l 0 $spacing-l;
    width: 100%;
    flex-direction: row;
    column-gap: $spacing-xl;
  }

  @include breakpoint-tablet-large {
    width: 768px;
  }

  .blurred-pic {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
    width: max-content;
    height: max-content;
    background-color: #ccc;
  }

  .internperiod-wrapper {
    &__left {
      display: flex;
      margin-bottom: $spacing-l;
      width: 60px;
      height: 60px;
      align-self: flex-start;

      @include breakpoint-tablet-large {
        width: 100px;
        height: 100px;
      }

      & > .preview {
        & > img {
          width: 60px;
          height: 60px;

          @include breakpoint-tablet-large {
            width: 100px;
            height: 100px;
          }
        }
      }

      @include breakpoint-tablet-large {
        flex-direction: column;
      }

      .kompetenser {
        @include breakpoint-tablet-small {
          border-bottom: solid 1px $primary-navy;
        }
      }

      &--section {
        .heading {
          font-size: 2rem;
          font-family: $header-font;
          display: flex;
          align-items: center;
          margin-bottom: $spacing-m;

          .icon {
            @include element_icon-space-right;
          }
        }
      }
    }

    &__middle {
      display: flex;
      flex-direction: column;
      row-gap: $spacing-s;
      width: 80%;

      @include breakpoint-tablet-large {
        width: 100%;
        margin-bottom: $spacing-l;
        padding: $spacing-l;
        row-gap: $spacing-m;
      }

      &--name {
        column-gap: $spacing-m;
        display: flex;
        align-items: center;
        text-transform: capitalize;
        font-size: 1.6rem;
        font-weight: 600;

        @include breakpoint-tablet-large {
          font-size: 1.8rem;
        }

        & > .search-card-icon {
          font-size: 1.4rem;

          @include breakpoint-tablet-large {
            font-size: 1.8rem;
          }
        }

        & > p {
          padding-left: $spacing-s;
        }
      }

      &--title {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        font-weight: 500;
        word-wrap: break-word;
        text-transform: capitalize;
        column-gap: $spacing-m;

        @include breakpoint-tablet-large {
          font-size: 1.6rem;
        }

        & > p {
          padding-left: $spacing-s;
        }
      }

      .blurred {
        color: transparent;
        text-shadow: 0 0 7px #000;

        & > .search-card-icon {
          color: black;
          -webkit-filter: blur(7px);
          -moz-filter: blur(7px);
          -o-filter: blur(7px);
          -ms-filter: blur(7px);
          filter: blur(7px);
          width: max-content;
          height: max-content;
          background-color: #ccc;
        }
      }

      .first-upper {
        text-transform: capitalize;
      }

      &--one-liner {
        display: flex;
        column-gap: $spacing-m;
        font-size: 1.4rem;

        @include breakpoint-tablet-large {
          font-size: 1.6rem;
        }

        p {
          padding-left: $spacing-s;
          overflow: hidden;
          -webkit-line-clamp: 3; /* number of lines to show */
          display: -webkit-box;
          -webkit-box-orient: vertical;
          font-style: italic;
          word-wrap: normal;
          max-width: 90%;

          @include breakpoint-tablet-large {
            -webkit-line-clamp: 2; /* number of lines to show */
          }
        }

        & > .search-card-icon {
          font-size: 1.8rem;
        }
      }

      .skills-container {
        display: flex;
        font-size: 1.6rem;
        column-gap: $spacing-m;

        @include breakpoint-tablet-large {
          margin-bottom: $spacing-m;
          max-height: 10.7rem;
          font-size: 1.8rem;
        }

        &__tags {
          overflow: hidden;
          max-width: 90%;
          display: -webkit-box;
          -webkit-line-clamp: 3; /* number of lines to show */
          line-clamp: 3;
          -webkit-box-orient: vertical;
        }

        button {
          @include button_clickable-tag;
          color: $primary-navy;
        }
      }

      .skills-container-empty {
        display: flex;
        font-size: 1.6rem;
        column-gap: $spacing-m;
        align-items: center;

        &__text {
          font-style: italic;
          font-size: 1.4rem;
        }

        @include breakpoint-tablet-large {
          margin-bottom: $spacing-m;
          max-height: 10.7rem;
          font-size: 1.8rem;
        }
      }

      @include breakpoint-tablet-small {
        width: 100%;
        margin-bottom: 0;
        background-color: $white;
        border-radius: 0;
        padding: 0;
        color: $primary-navy;
      }

      &--education {
        h4 {
          font-size: $body-copy-size;
          font-weight: 400;
        }
      }

      &--region {
        h4 {
          font-size: $body-copy-size;
          font-weight: 400;
        }
      }
    }

    &__right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      color: $primary-navy;

      .favorite-btn {
        align-self: flex-end;
        background-color: transparent;
        border: none;
        font-size: 2.5rem;
        cursor: pointer;

        .favorited {
          color: $yellow;
        }

        .add-icon {
          transition: transform 0.2s;
        }

        .add-icon:hover {
          color: $primary-blue;
          transform: scale(1.3);
        }
      }
    }
  }

  .no-entries {
    font-size: 1.6rem;
    margin-bottom: $spacing-m;
  }
  .no-results {
    font-size: 1.6rem;
  }
  transition: box-shadow 0.1s;
}

#internperiod-container:hover {
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.3);
}
