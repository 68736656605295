@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

.unsubscribe-container {
  @include mixin-parent-container;

  h1 {
    @include page-heading;
    @include breakpoint-tablet-large {
      font-size: 5.4rem;
    }
    @include breakpoint-desktop {
      font-size: 6.4rem;
      line-height: 6rem;
    }
  }

  .blue-div {
    @include blue-div;
  }

  .content-container {
    @include content-container;
    @include breakpoint-tablet-large {
      margin-top: $spacing-xxl;
    }

    > h1 {
      margin-top: $spacing-xl;
    }

    .unsubscribe-text {
      h2 {
        font-size: $big-label-size;
        margin-bottom: $spacing-l;
      }

      > p {
        font-size: $feature-txt-size-mobile;
        margin-bottom: $spacing-xl;
      }

      div {
        display: flex;
        flex-direction: column;

        button:first-child {
          @include button_alert;
          @include breakpoint-desktop {
            margin-bottom: $spacing-xl;
          }
        }

        button:nth-child(2) {
          @include button_secondary;
        }
      }
    }
  }
}
