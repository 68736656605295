@import "../../../styles_NEW_STRUCTURE/globalVariables";
@import "../../../styles_NEW_STRUCTURE/mixins/breakpoints";

@keyframes showblob {
  0% {
    transform: scale(0);
  }
  20% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

#nav-container {
  //   opacity: 0.1;
  width: 100%;
  background-color: #f7f7f7;
  position: fixed;
  top: 0;
  z-index: 100000;
  height: $navbar-height;
  border-bottom: 1px solid $primary-navy;

  @include breakpoint-desktop {
    // background-color: $white;
    border-bottom: 1px solid $primary-navy;
  }

  .link-name {
    cursor: pointer;
  }

  .nav-relative-div {
    position: relative;
    display: flex;
    align-items: center;
    height: $navbar-height;

    .logoImage-nav {
      height: 6rem;
      margin: 0 0 0 $spacing-l;

      @include breakpoint-desktop {
        margin: 0 0 0 $spacing-xxl;
      }
    }

    .logoImage-nav:hover {
      cursor: pointer;
    }

    .icon-container {
      position: absolute;
      right: $spacing-l;

      .closed-icon {
        &.blob::after {
          content: "";
          position: absolute;
          top: 0;
          right: 0;
          width: 1.8rem;
          height: 1.8rem;
          background: url("/assets/messaging-blob.png") center center;
          background-size: cover;

          @include breakpoint-desktop {
            width: 2.5rem;
            height: 2.5rem;
          }
        }

        &.blob--show::after {
          animation: showblob 0.5s;
        }
      }
    }

    .menu-icon {
      font-size: $heading-size;
      color: $primary-navy;
    }

    .menu-options {
      padding: $spacing-xl $spacing-l $spacing-l $spacing-l; // TD: Added larger top margin 14/7
      width: 100%;
      height: 91vh;
      position: absolute;
      top: 9vh;
      left: 0;
      z-index: -10;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      background: linear-gradient(
        to right,
        rgba($primary-blue, 0.5) 0px,
        rgba($primary-blue, 0.5) 20%,
        $white 20%,
        $white 100%
      );
      // animation:
      animation-name: fadeInLeft;
      animation-duration: 0.4s ease-out;
    }

    .link-name {
      font-size: 2rem;
      // margin: $spacing-l $spacing-l 0 0; // TD: Commented out 14/7
      margin-bottom: $spacing-l; // TD: Added 14/7
      font-weight: 500;
      font-family: $header-font;
      text-decoration: none;
      color: $primary-navy;
      border: none;
      outline: none;
      background-color: transparent;
      &:hover {
        color: blue;
      }

      &.blob {
        position: relative;
      }

      &.blob::after {
        content: "";
        position: absolute;
        top: -0.6rem;
        right: -2;
        width: 1.8rem;
        height: 1.8rem;
        background: url("/assets/messaging-blob.png") center center;
        background-size: cover;
      }
    }

    @include breakpoint-desktop {
      .nav-desktop {
        display: flex;
        justify-content: flex-end;
        width: 100%;

        .link-name-desktop {
          font-size: 1.6rem;
          margin: 0 $spacing-xl 0 0;
          font-weight: 600;
          font-family: $header-font;
          text-decoration: none;
          color: $primary-navy;
          border: none;
          outline: none;
          background-color: transparent;
          cursor: pointer;

          &.blob {
            position: relative;
          }

          &.blob::after {
            content: "";
            position: absolute;
            top: -0.6rem;
            right: -1;
            width: 20px;
            height: 20px;
            background: url("/assets/messaging-blob.png") center center;
            background-size: cover;
          }

          &.Meddelande &:hover {
            color: blue;
          }

          &:last-child {
            margin: 0 $spacing-xxl 0 0;
          }
        }
      }
    }
  }

  .logout-btn {
    background-color: transparent;
    border: none;
    font-size: 2rem;
    // margin: $spacing-l $spacing-l 0 0; // TD: Removed 14/7
    font-weight: 600;
    font-family: $header-font;
    text-decoration: none;
    color: $primary-navy;

    &:hover {
      color: blue;
    }

    @include breakpoint-desktop {
      border: none;
      font-size: 1.6rem;
      margin: 0 $spacing-xl 0 0;
      font-weight: 600;
      font-family: $header-font;
      text-decoration: none;
      color: $primary-navy;
      background-color: transparent;

      &:hover {
        color: blue;
        cursor: pointer;
      }
    }
  }
}

@keyframes fadeInLeft {
  0% {
    left: 110%;
    background: linear-gradient(
      to right,
      rgba($primary-blue, 0) 0px,
      rgba($primary-blue, 0) 20%,
      $white 20%,
      $white 100%
    );
  }

  70% {
    background: linear-gradient(
      to right,
      rgba($primary-blue, 0) 0px,
      rgba($primary-blue, 0) 20%,
      $white 20%,
      $white 100%
    );
  }

  80% {
    background: linear-gradient(
      to right,
      rgba($primary-blue, 0.1) 0px,
      rgba($primary-blue, 0.1) 20%,
      $white 20%,
      $white 100%
    );
  }

  90% {
    background: linear-gradient(
      to right,
      rgba($primary-blue, 0.3) 0px,
      rgba($primary-blue, 0.3) 20%,
      $white 20%,
      $white 100%
    );
  }

  100% {
    left: 0;
    background: linear-gradient(
      to right,
      rgba($primary-blue, 0.5) 0px,
      rgba($primary-blue, 0.5) 20%,
      $white 20%,
      $white 100%
    );
  }
}
