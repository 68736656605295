@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

.spinner {
  border-radius: 50%;
  border-top: 0.5rem solid $primary-blue;
  border-right: 0.5rem solid $white;
  border-bottom: 0.5rem solid $white;
  border-left: 0.5rem solid $white;
  animation: spin 1s linear infinite;
  flex-grow: 0 !important;
  flex-shrink: 0 !important;

  filter: drop-shadow(0px 0px 0.2rem lightgrey);

  &.small {
    border-width: 0.3rem;
    height: 1.7rem;
    width: 1.7rem;
  }

  &.medium {
    border-width: 0.4rem;
    height: 2.5rem;
    width: 2.5rem;
  }

  &.large {
    border-width: 0.8rem;
    height: 5rem;
    width: 5rem;
  }

  &.x-large {
    border-width: 1rem;
    height: 7.5rem;
    width: 7.5rem;
  }
  &.xx-large {
    border-width: 1.2rem;
    height: 12rem;
    width: 12rem;
  }
}

@keyframes spin {
  from {
    rotate: 0deg;
  }
  to {
    rotate: 360deg;
  }
}
