//! MEDIA QUERYS

$phone-size: 599px;
$tablet-small-size: 768px;
$tablet-big-size: 1024px;
$desktop-size: 1200px;

//! MARGINS / PADDINGS

$navbar-height: 9vh;

$border-radius-small: 0.8rem;
$border-radius-large: 1.6rem;

//85px
$spacing-xxxl: 8.5rem;
// 64px
$spacing-xxl: 6.4rem;
// 32px
$spacing-xl: 3.2rem;
// 16px
$spacing-l: 1.6rem;
// 8px
$spacing-m: 0.8rem;
// 4px
$spacing-s: 0.4rem;
// 2px
$spacing-xs: 0.2rem;

//spacing search
$spacing-search: 2.4rem;
