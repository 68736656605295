@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

.payment-container {
  padding: $spacing-l;
  border: 1px solid $grey;
  border-radius: 1.6rem;

  & > div {
    @include content-container;
  }
}
