@use "../variables/fonts" as *;
@use "../variables/colors" as *;
@use "../variables/sizes" as *;
@use "./breakpoints" as *;

@mixin basic-input {
  font-size: $body-copy-size;
  font-family: $body-font;
  color: $grey;
  background-color: $white;
  padding: $spacing-m $spacing-l;
  border-radius: $border-radius-small;
  word-break: break-word;
  white-space: pre-wrap;
  resize: none;
  outline: none;
  border: 0;

  transition: box-shadow 0.1s ease;
  $border-width: 1px; // WE BREAK OUT THE BORDER WIDTH INTO A VARIABLE FOR EASY FUTURE MODIFICATIONS.
  width: calc(
    100% - calc($border-width * 2)
  ); // WE NEED TO SUBTRACT THE BOXSHADOW BORDER FROM THE WIDTH AS BOX SHADOW IS NOT PART OF THE SIZE CALCULATION.
  box-shadow: 0px 0px 1px $border-width rgba($grey, 0.4); // WE USE THIS A BORDER TO PREVENT SIZING CHANGES DUE TO BORDER ANIMATION.
  // margin: $border-width; // TO OFFSET THE BOXSHADOW BORDER.
  // margin-bottom: $spacing-l;

  &:focus {
    // outline: 2px solid $primary-navy;
    box-shadow: 0px 0px 0px 1px $primary-navy;
    border: none;
    color: $primary-navy;
  }

  &[type="date"] {
    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
    }
  }
}

@mixin basic-input-visuals {
  font-size: $body-copy-size;
  font-family: $body-font;
  color: $grey;
  background-color: $white;
  word-break: break-word;
  white-space: pre-wrap;
  resize: none;
  outline: none;
  border: 0;
  border-radius: $border-radius-small;
  box-shadow: 0px 0px 1px 1px rgba($grey, 0.4);
  transition: box-shadow 0.1s ease;
  &:focus {
    box-shadow: 0px 0px 0px 1px $primary-navy;
    color: $primary-navy;
  }
  &[type="date"] {
    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
    }
  }
}
