@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

.success-container {
  @include container_parent;

  h1 {
    @include text_page-heading;
    margin-top: $spacing-l;
    font-size: 4.4rem;
    line-height: 4rem;
    @include breakpoint-tablet-large {
      font-size: 5rem;
    }
    @include breakpoint-desktop {
      font-size: 6.4rem;
      line-height: 6rem;
    }
  }

  .blue-div {
    @include container_blue-div;
  }

  .content-container {
    @include container_content;
    @include breakpoint-tablet-large {
      margin-top: $spacing-xxl;
    }

    .back-btn {
      font-size: 1.8rem;
      margin: 0 0 $spacing-xl 0;
      border: none;
      background-color: transparent;
      font-weight: bold;
      cursor: pointer;
      align-items: center;
      align-self: flex-start;
      flex-direction: row;
      display: flex;

      @include breakpoint-tablet-small {
        margin-bottom: $spacing-l 0 $spacing-xxl 0;
      }
    }

    .mobile-back-btn {
      @include link-btn;
      align-self: flex-start;
    }

    .back-icon {
      margin-right: $spacing-l;
    }

    .mobilesize-heading {
      @include text_page-heading;
    }

    h2 {
      font-size: $big-label-size;
      margin-bottom: $spacing-l;
    }

    > p {
      font-size: $feature-txt-size-mobile;
    }

    p:first-of-type {
      margin-bottom: $spacing-l;
    }

    p:last-of-type {
      margin-bottom: $spacing-xl;
    }

    .primary-btn {
      @include button_primary;
    }

    .secondary-btn {
      @include button_secondary;
      align-self: flex-start;
    }

    .icon {
      margin-left: $spacing-l;
    }
  }
}

.redirect-link {
  @include button_secondary;

  .icon {
    @include element_icon-space-left;
  }
}
