@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

.notFound-container {
  padding: $spacing-l;
  color: $primary-navy;
  //margin-top: $navbar-height;
  display: flex;
  min-height: calc(100vh - $navbar-height);
  flex-direction: column;
  // justify-content: center;
  align-items: center;

  background-image: url("../../../../../public/img/backgrounds/background_tablet_registration_06.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  @include breakpoint-tablet-small {
    background-image: url("../../../../../public/img/backgrounds/background_desktop_registration_06.png");
  }

  .notFound-wrapper {
    width: 340px;
    display: flex;
    flex-direction: column;
    padding: $spacing-l;
    border-radius: $spacing-l;
    background-color: rgba($background-grey, 0.3);

    .notFound-text {
      font-size: 4.8rem;
      line-height: 5.5rem;
      margin-bottom: $spacing-l;
      font-family: $header-font;

      @include breakpoint-tablet-small {
        margin-bottom: $spacing-l;
      }
    }

    p {
      font-size: 2.5rem;
      margin-bottom: $spacing-l;
    }

    .no-access-btn {
      @include button-primary;
    }
  }
}
