@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

.dropdown-search-schools {
  @include default-dropdown;
  .input-label {
    @include basic-label;
  }
  .top {
    transition: box-shadow 0.1s ease;
    flex-direction: column;
    align-items: stretch;
    gap: $spacing-m;
    cursor: default;

    .text-field {
      .magnify {
        font-size: $btn-txt-size;
      }
      input {
        @include basic-input;
        pointer-events: all;
        padding: 0;
        border-radius: 0;
        box-shadow: none;
        flex-grow: 1;
        &:focus {
          box-shadow: none;
        }
      }
    }

    .active-tags {
      display: flex;
      border: 0;

      &.single-choice .tags-wrapper {
        flex-wrap: nowrap;
        flex-direction: column;
        // border: 5px solid red;
        align-items: stretch;
        .tag span {
          flex-grow: 1;
        }
      }

      .tags-wrapper {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: $spacing-m;
        // border: 2px solid red;

        .tag {
          @include basic-input-visuals;
          cursor: default;
          padding: $spacing-m;
          margin: 0;
          display: flex;
          align-items: center;
          gap: $spacing-m;

          .remove-tag-button {
            cursor: pointer;
            font-size: 1.5rem;
            color: $grey;
            border: 2px solid $grey;
            border-radius: 50%;
            transition: color 0.1s ease, border-color 0.1s ease;

            &:hover {
              color: $primary-blue;
              border-color: $primary-blue;
            }
          }
        }
      }

      .clear-tags {
        @include button_icon_scale;
        color: $primary-blue;
        font-size: $btn-txt-size;
        align-self: flex-end;
        padding: 0 0 0 calc($spacing-m * 2);
        cursor: pointer;
      }
    }
  }

  & > .menu .entry {
    span {
      align-items: center;
      gap: $spacing-m;
    }
    &.active {
      color: $secondary-blue;
    }
    &.hint {
      color: $secondary-purple;
    }
  }
  & > .menu .loader {
    display: flex;
    justify-content: center;
  }
}
