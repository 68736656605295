@use "/src/styles_NEW_STRUCTURE/globalAll" as *;

#school-user-profile {
  // @include page-base;
  display: flex;
 // margin-top: $navbar-height;
  color: $primary-navy;
  min-height: 100vh - $navbar-height;
  flex-direction: column-reverse;
  justify-content: center;
  background-image: url("../../../../public/img/backgrounds/background_tablet_registration_15.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;

  @include breakpoint-desktop {
    flex-direction: row;
    background-image: url("../../../../public/img/backgrounds/background_desktop_registration_15.png");
  }

  .section-left {
    align-items: flex-end;
    justify-content: center;
    padding: $spacing-l;
  }

  .section-right {
    align-items: flex-start;
    justify-content: center;
    padding: $spacing-l;

    .view-students-button {
      @include button-primary;
      width: 100%;
      align-self: center;
      margin-top: $spacing-l !important;
    }

    .view-students-button:hover {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}
