@use "../globalVariables" as *;
@use "./general" as *;
@use "./inputs" as *;
@use "./buttons" as *;

@mixin default-dropdown {
  position: relative;
  min-width: 0px;
  display: flex;
  flex-direction: column;

  &.Disabled {
    position: relative;
    pointer-events: none;

    // &::after {
    //   position: absolute;
    //   top: 0;
    //   left: 0;
    //   text-shadow: 0 0 0.5rem $primary-navy;
    //   content: "Disabled";
    //   width: 100%;
    //   height: 100%;
    //   background-color: $primary-grey;
    //   border-radius: $border-radius-small;
    //   opacity: 1;

    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   color: $white;
    // }
  }

  .top {
    @include basic-input;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: $spacing-m;
    background-color: $white;
    cursor: pointer;

    & > .text-field {
      display: flex;
      align-items: center;
      pointer-events: none;
      color: $primary-navy;
      border: 0;
      box-shadow: none;
      font-family: $body-font;

      & > input {
        @include basic-input;
        padding: 0 0 0 2px;
        box-shadow: none;
      }
    }

    & > .active-entries {
      display: flex;
      border: 0;

      & > .entries-wrapper {
        flex-grow: 1;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-start;
        gap: $spacing-m;
        // border: 2px solid red;

        & > .active-entry {
          @include basic-input-visuals;
          cursor: default;
          padding: $spacing-s;
          margin: 0;
          display: flex;
          align-items: center;
          gap: $spacing-m;

          .remove-tag-button {
            cursor: pointer;
            font-size: 1.5rem;
            color: $grey;
            border: 2px solid $grey;
            border-radius: 50%;
            transition: color 0.1s ease, border-color 0.1s ease;

            &:hover {
              color: $primary-blue;
              border-color: $primary-blue;
            }
          }
        }
      }

      .clear-tags {
        @include button_icon_scale;
        color: $primary-blue;
        font-size: $btn-txt-size;
        align-self: flex-end;
        padding: 0 0 0 calc($spacing-m * 2);
        cursor: pointer;
      }
    }

    .arrow {
      position: absolute;
      right: 0;
      pointer-events: none;
      margin: 1rem;
      flex-shrink: 0;
      flex-grow: 0;
      background-color: transparent;
      transition: transform 0.2s ease;
      font-size: 2.4rem;

      &.open {
        transform: rotate(-180deg);
      }

      &.close {
        transform: rotate(0deg);
      }
    }
  }

  .selected {
    box-shadow: 0px 0px 1px 1px black;
  }

  .menu {
    background-color: $light-grey;
    border: 0;
    display: flex;
    flex-direction: column;
    transition: max-height 0.2s ease, opacity 0.2s ease,
      background-color 0.2s ease, box-shadow 0.2s ease;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    top: 110%;
    width: 100%;
    border-radius: $border-radius-small;
    // box-shadow: $card-shadow; // WILL BE USED ONCE CONVERT TO MUI IS MERGED
    box-shadow: $new-box-shadow;
    z-index: 100;

    &.opened {
      max-height: calc(20 * #{$body-copy-size});
      height: auto;
      opacity: 1;
      pointer-events: all;

      .entry {
        opacity: 1;
        transition-delay: 0.2s;
        pointer-events: all;
      }
    }

    &.closed {
      max-height: 0px;
      transition-delay: 0.2s;
      opacity: 0;
      pointer-events: none;
      .entry {
        opacity: 0;
        pointer-events: none;
      }
    }

    &.relative {
      top: 0;
      margin-top: 0.3rem;
      position: relative;
    }

    .entry:first-child span {
      border-top-left-radius: $border-radius-small;
      border-top-right-radius: $border-radius-small;
    }
    .entry:last-child span {
      border-bottom-right-radius: $border-radius-small;
      border-bottom-left-radius: $border-radius-small;
    }

    .entry {
      cursor: pointer;
      padding: 0.1rem;
      transition: opacity 0.2s ease, background-color 0.2s ease, color 0.2s ease;
      span {
        font-size: $body-copy-size;
        font-family: $body-font;
        box-shadow: none;
        padding: 1rem;
        display: flex;
        align-items: center;
        gap: $spacing-m;
        background-color: $light-grey;
        width: 100%;
        max-width: 100%;
        word-wrap: break-word;
        transition: ease, background-color 0.2s ease, box-shadow 0.2s ease;
        transition-delay: 0s;
        &:hover {
          background-color: $white;
          box-shadow: inset 0 0 2px 0 rgba(0, 0, 0, 0.25);
        }
      }

      &.disabled {
        pointer-events: none;
      }

      &.active {
        color: $primary-blue;
      }
    }
  }
}
