@use "../variables/sizes" as *;
@use "./breakpoints" as *;
@use "../variables/colors" as *;

// WHEN YOU CREATE A NEW PAGE, CREATE A WRAPPER WITH A UNIQUE
// ID. INSIDE THE scss FILE OF SAID PAGE, THAT WRAPPER NEEDS
// TO @include page-base

// THIS MIXIN EXPECTS YOU TO HAVE A MINIMUM OF 2 CHILDREN
// INSIDE THE <main> TAG OF THE PAGE. AS IT IS USING
// flex-basis
@mixin page-base {
  flex-grow: 1;
  //margin-top: $navbar-height;
  display: flex;
  flex-direction: column;
  color: $primary-navy;

  @include breakpoint-tablet-small {
    flex-direction: row;
  }

  & > * {
    flex-basis: 100%;
    display: flex;
    flex-direction: column;

    @include breakpoint-tablet-small {
      // flex-basis: 50%;
    }
  }
}
